
import { defineComponent, ref, inject, computed, onMounted } from "vue";
import { useStore } from "vuex";
import Form from "./ProductInventoryForm.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    Form,
  },
  setup() {
    /* DATA */
    const store = useStore();
    const router = useRoute();
    const columns = [
      {
          title: 'Type',
          dataIndex: 'type'
      },
      {
          title: 'Operator 1',
          dataIndex: 'operator_1'
      },
      {
          title: 'Value 1',
          dataIndex: 'value_1'
      },
      {
          title: 'Condition',
          dataIndex: 'condition'
      },
      {
          title: 'Operator 2',
          dataIndex: 'operator_2'
      },
      {
          title: 'Value 2',
          dataIndex: 'value_2'
      },
      {
          title: 'Variance Label',
          dataIndex: 'label',
          slots: { customRender: "label" },
      },
      {
        title: "Action",
        dataIndex: "action",
        slots: { customRender: "action" },
        align: 'center'
      }
    ];
    const product_inventory_form = ref<InstanceType<typeof Form>>();
    const product = inject<any>('product');

     /* METHODS */
    const create = () => {
      product_inventory_form.value?.create();
    };

    const edit = (record: any) => {
      product_inventory_form.value?.edit(record);
    };

    const getAllVariances = () => {
      store.dispatch("Option/getAllVariances");
    };

    const remove = (row:any) => {
      product.product_variance.map(function (value:any, key:any) {
        if (value.id == row.id) {
            product.product_variance.splice(key, 1);
        }
      });
    };

    const deleteRecord = (row:any) => {
      store.dispatch("ProductVariance/destroy", row.id).then((response) => (
        product.product_variance.map(function (value:any, key:any) {
          if (value.id == response.data) {
              product.product_variance.splice(key, 1);
          }
        })
      ));
      
    };

    /* COMPUTED */
    const all_variances = computed(() => store.getters["Option/all_variances"]);
    const isNew = computed(() =>
      router.name == "product-form-create" ? true : false
    );

    /* MOUNTED */
    onMounted(getAllVariances);

    return {
        columns,
        product,
        product_inventory_form,
        all_variances,
        isNew,
        create,
        edit,
        remove,
        deleteRecord
    };
  },
});
