
import { defineComponent, ref, computed, inject, reactive, onMounted, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Modal } from 'ant-design-vue'

interface FormState {
  id: string;
  operator_1: string;
  value_1: string;
  operator_2: string;
  value_2: string;
  condition: string;
  variance_id: string;
  product_id: string;
  type: string
  period: string
}

const initialState = {
  id: '',
  operator_1: '',
  value_1: '',
  operator_2: '',
  value_2: '',
  condition: '',
  variance_id: '',
  product_id: '',
  type: '',
  period: 'Daily'
}

export default defineComponent({

  setup () {
    /* DATA */
    const store = useStore()
    const router = useRoute()
    const form = reactive<FormState>({...initialState})
    const product = inject<any>('product')
    const visible = ref(false)
    const form_action = ref('save')
    const id = ref(0)
    const operator = ['Greater Than', 'Less Than', 'Equal']
    const condition = ['And', 'Or']
    const type = ['Gain', 'Loss']

    /* METHODS */
    const create = () => {
      Object.assign(form, initialState)
      visible.value = true
      form_action.value = 'save'
    }

    const edit = (record: any) => {
      const {
        id,
        operator_1,
        value_1,
        operator_2,
        value_2,
        condition,
        variance_id,
        product_id,
        type,
        period
      } = toRefs(form)
      id.value = record.id
      operator_1.value = record.operator_1
      value_1.value = record.value_1
      operator_2.value = record.operator_2
      value_2.value = record.value_2
      condition.value = record.condition
      variance_id.value = record.variance_id
      product_id.value = record.product_id
      type.value = record.type
      period.value = 'Daily'
      visible.value = true
      form_action.value = 'update'
    }

    const areFieldsValid = () => {
      if (!form.operator_1 || !form.value_1 || !form.type || !form.variance_id) {
        Modal.error({
          title: 'Error',
          content: 'Type, variance label, operator 1, and its corresponding variance % are required fields.'
        })
        return false
      }

      if (form.condition && (!form.operator_2 || !form.value_2)) {
        Modal.error({
          title: 'Error',
          content: 'Operator 2 and its variance % must be present if a condition has been selected.'
        })
        return false
      }

      if ((!form.operator_2 && form.value_2) || (form.operator_2 && !form.value_2)) {
        Modal.error({
          title: 'Error',
          content: 'Operator 2 is required if its corresponding variance % is present and vice versa.'
        })
        return false
      }

      if (!form.condition && (form.value_2 && form.operator_2)) {
        Modal.error({
          title: 'Error',
          content: 'Condition is required when operator 2 and its corresponding variance % is present.'
        })
        return false
      }

      return true
    }

    const save = () => {
      if (! areFieldsValid()) {
        return
      }

      form_action.value == 'save' ? addProductVariance(form) : updateProductVariance(form)
    }

    const addProductVariance = (payload: any) => {
      if (isNew.value) {
        product.product_variance.push({
          id: id.value++,
          operator_1: form.operator_1,
          operator_2: form.operator_2,
          value_1: form.value_1,
          value_2: form.value_2,
          condition: form.condition,
          variance_id: form.variance_id,
          type: form.type,
          period: form.period
        })
        visible.value = false
      } else {
        payload.product_id = product.id
        store
          .dispatch('ProductVariance/store', payload)
          .then((response) => (
            visible.value = false,
            product.product_variance.push({
              id: response.data.id,
              operator_1: response.data.operator_1,
              operator_2: response.data.operator_2,
              value_1: response.data.value_1,
              value_2: response.data.value_2,
              condition: response.data.condition,
              variance_id: response.data.variance_id,
              type: response.data.type,
              period: response.data.period
            })
          ))
      }
    }

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const updateProductVariance = (payload: any) => {
      let id = form.id

      if (isNew.value) {
        product.product_variance.find(function (value: any, key: any) {

          if (value.id == id) {
            product.product_variance[key] = Object.assign({}, form)
          }
        })
        visible.value = false
      } else {
        payload.product_id = product.id
        store
          .dispatch('ProductVariance/update', payload)
          .then((response) => (
            visible.value = false,
            product.product_variance.find(function (value: any, key: any) {
              if (value.id == response.data.id) {
                product.product_variance[key] = Object.assign({}, response.data)
              }
            })
          ))
      }

    }

    const cancel = () => {
      visible.value = false
    }

    const getAllVariances = () => {
      store.dispatch('Option/getAllVariances')
    }

    /* COMPUTED */

    const isNew = computed(() =>
        router.name == 'product-form-create'
    )

    const all_variances = computed(() => store.getters['Option/all_variances'])

    /* MOUNTED */
    onMounted(getAllVariances)

    return {
      product,
      form,
      visible,
      form_action,
      isNew,
      operator,
      condition,
      type,
      all_variances,
      save,
      create,
      edit,
      cancel,
      filterOption
    }
  },
})
