
import {
  defineComponent,
  ref,
  reactive,
  computed,
  onMounted,
  UnwrapRef,
  toRefs,
  provide,
  createVNode
} from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
// import ProductCode from '../product/components/ProductCode.vue'
import ProductInventory from '../product/components/ProductInventory.vue'
import ProductInventorySpecificDays from '../product/components/ProductInventorySpecificDays.vue'
import { FormState } from '@/interfaces/product'
import { Modal } from 'ant-design-vue'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  // beforeRouteLeave(to, from, next) {
  //   console.log(to)
  // // Do something

  //   return next({ path: '/dashboard' }); // Go somewhere else if necessary
  //   // next();                 // Or stay here
  // },

  components: {
    // ProductCode,
    ProductInventory,
    ProductInventorySpecificDays
  },

  setup (props) {
    const promptExit = ref(true)

    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const volume_threshold = ref('')
    const data = reactive([])
    const visible = ref(false)
    const isLoading = ref<boolean>(false)

    //   onBeforeRouteLeave((to, from, next) => {

    //     // const token = localStorage.getItem('DWSR_TOKEN');
    //     if(from.path === '/product/create') next({ name: 'Login' })
    // else next()
    //   // // 登录拦截
    //   // let loginPages = ['/product/create'];
    //   // let pagePath = to.path;
    //   // if (loginPages.includes(pagePath)) {
    //   //   localStorage.getItem('isLogin') ? '' : router.push('/login');
    //   // }
    //   // next()
    //   })
    const form: UnwrapRef<FormState> = reactive({
      id: '',
      product_name: '',
      product_category_id: '',
      product_type_id: '',
      volume_threshold: '',
      product_codes: [],
      product_variance: [],
      product_variance_5_and_30_days: [],
      remove_product_codes: []
    })
    provide('product', form)

    const handleFinish = async (values: FormState) => {
      if (props.id) {
        form.id = props.id
        store.dispatch('Product/update', form)
      } else {
        store.dispatch('Product/store', form)
      }
    }

    const getAllProductCategories = () => {
      store.dispatch('Option/getAllProductCategories')
    }
    const getAllProductTypes = () => {
      store.dispatch('Option/getAllProductTypes')
    }
    const getProduct = () => {
      if (props.id) {
        isLoading.value = true
        store
            .dispatch('Product/show', props.id)
            .then(
                (res) => (
                    (form.id = res.data.id),
                        (form.product_name = res.data.product_name),
                        (form.product_category_id = res.data.product_category_id),
                        (form.product_type_id = res.data.product_type_id),
                        (form.volume_threshold = res.data.volume_threshold),
                        (form.product_codes = res.data.product_codes),
                        (form.product_variance = res.data.daily_variance),
                        (form.product_variance_5_and_30_days = res.data.specific_variance),
                        isLoading.value = false
                )
            )
      }
    }

    const all_product_categories = computed(
        () => store.getters['Option/all_product_categories']
    )
    const all_product_types = computed(
        () => store.getters['Option/all_product_types']
    )

    const handleBack = () => {
      router.push('/products')
    }

    const filterOption = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    onMounted(getAllProductTypes)
    onMounted(getAllProductCategories)
    onMounted(getProduct)

    return {
      form,
      volume_threshold,
      all_product_categories,
      all_product_types,
      data,
      visible,
      promptExit,
      handleFinish,
      handleBack,
      isLoading,
      filterOption
    }
  },
})
